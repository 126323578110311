






















































































import Vue from "vue";

import img_torcida_quem_somos from "@/assets/img_torcida_quem_somos.png";
import predio_quemsomos from "@/assets/predio_quemsomos.png";

export default Vue.extend({
  name: "QuemSomos",
  mounted() {
    window.scrollTo(0, 0);
  },
  computed: {
    img_torcida_quem_somos() {
      return img_torcida_quem_somos;
    },
    predio_quemsomos() {
      return predio_quemsomos;
    },
  },
});
